import React from "react"
import Layout from "../../components/layout"
import rezi from '../../assets/rezi-thumb.jpg'
import main from '../../assets/casestudies/resideo-main.png'
import fullstack from '../../assets/casestudies/resideo-fullstack.png'
import navigation from '../../assets/casestudies/resideo-navigation.gif'
import navigation2 from '../../assets/casestudies/resideo-navigation2.png'
import CenteredContent from '../../components/centeredContent'
import FullWidthContent from '../../components/fullWidthContent'

const DNB = () => (
  <Layout headerType="resideo" headerText='Resideo'>
    <CenteredContent
      image1={rezi}
      header="Resideo"
      body="Resideo is a Honeywell spinoff company that offers top quality IOT devices for comfort, security & water. My team was tasked with creating the Resideo Pro Portal, a software tool for professional hardware installers to manage their devices and business."
      rotation="3deg"
    />
    <FullWidthContent
      bgcolor1={'#f1f1f1'}
      image={main}
      header="Authentication"
      body="At the center of every admin portal is authentication. I helped lead the effort to integrate Auth0 into the Resideo Pro portal and built a custom universal login application used by the Resideo Pro Portal  and Pro Installer mobile application for our users to login and manage their accounts including by a robust deployment process and documentation."
      rotation="3deg"
      bottomAngleMargin="-60px"
      bgPosition="top center"
    />
    <CenteredContent
      image1={navigation}
      image2={navigation2}
      type='mobile'
      header="Navigation"
      body="Navigating a complex hierarchy of pages on a site can be tricky -- especially on mobile. I worked closely with our UX designers to build a web & mobile navigation that was easy to use and accessible."
      rotation="-3deg"
    />
    <FullWidthContent
      bgcolor1={'#f1f1f1'}
      image={fullstack}
      header="Back end development"
      body="Along with front end UI work I also helped contribute to our GraphQL gateway by adding a new service for integrating with the email service Mailjet. To ensure quality and stability I added a rich test suite for mocking requests and error handling."
      rotation="-3deg"
      bottomAngleMargin="-60px"
      bgPosition="top center"
    />
  </Layout>
)

export default DNB
